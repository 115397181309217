<template>
	<footer :class="{ 'has-background-white': useWhiteBackground }" class="footer">
		<div class="container pt-5 is-flex is-justify-content-space-between">
			<div class="py-2">© Alle rettigheder forbeholdes Minlæring {{ year }}</div>
			<div class="is-flex links-container">
				<a
					v-for="link in links"
					:key="link.title"
					class="px-5 py-2"
					:href="link.href"
					target="_blank"
				>
					<h3>{{ link.title }}</h3>
				</a>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: 'Footer',
	data() {
		return {
			year: new Date().getFullYear(),
			links: [
				{
					title: 'Blog',
					href: 'https://blog.minlaering.dk',
				},
				{
					title: 'Kom godt i gang',
					href: 'https://support.minlaering.dk/blog/kom-godt-i-gang',
				},
				{
					title: 'Hjælp og support',
					href: 'https://support.minlaering.dk/',
				},
				{
					title: 'Kontakt os',
					href: 'https://www.minlaering.dk/kontakt-os',
				},
			],
		};
	},
	computed: {
		useWhiteBackground() {
			const whiteBackgroundRoutes = ['book_chapter_section', 'assignment', 'screening'];
			return whiteBackgroundRoutes.includes(this.$route.name);
		},
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
@import '@/assets/sass/abstracts/mixins';

.footer {
	font-family: $family-primary;
	font-size: $size-7;
	color: $grey;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;

	a {
		font-size: $size-6;
		color: inherit;

		&:hover {
			color: $grey-dark;
		}

		&.logo-link {
			font-size: 24px;
			font-weight: 700;

			img.logo {
				width: 240px;
				vertical-align: middle;
			}
		}
	}
	.container {
		border-top: 3px solid $grey-light;
		justify-content: space-between;

		@include mixin-tablet-down {
			flex-direction: column-reverse;
			align-items: center;
			text-align: center;
		}
	}
	.links-container {
		@media screen and (max-width: 475px) {
			flex-wrap: wrap;
			a {
				width: 50%;
			}
		}
	}
}
</style>
